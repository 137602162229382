import React, { ReactElement } from 'react';
import { themeValue } from "@kursk/themes";
import { VideoContentSochi } from "./VideoContent.sochi/VideoContent.sochi";
import { VideoContentSakhalin } from "./VideoContent.sakhalin/VideoContent.sakhalin";

export function VideoContent(): ReactElement {

  return themeValue({
    sochi: <VideoContentSochi />,
    sakhalin: <VideoContentSakhalin />,
    default: <></>,
  });
}
