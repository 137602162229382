import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../urls";

interface IState {
  items: string[];
}

export const loadSellerNames = createAsyncThunk<string[], any>('sellerNames/load', async () => {
  const response = await axios.put(api.goods.getSellers.put());

  if (!response?.data?.sellerNames?.length) {
    return null;
  }

  return response?.data?.sellerNames;
});

export const sellerNamesSlice = createSlice<IState, any>({
  name: 'sellerNames',
  initialState: {
    items: [],
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadSellerNames.fulfilled, (state, { payload }) => {
      if (payload) {
        state.items = payload;
      }
    });
  }
});
