import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../../urls';
import {
  FAVORITES_ENTITY_TYPES,
  TAddToFavoritesRequestParams,
  TCheckFavoriteRequestParams,
  TDeleteFromFavoritesRequestParams,
  TFavoritesState,
  TFavoritesTypes,
  TLoadFavoritesReturnType
} from "../../types/favorites";
import { initialState } from "./constants";
import { SliceAction } from "../../types/sliceAction";
import { FAVORITE_LIST_LIMIT } from "../../../../kursk/components/pages/ProfilePage/Favorites/constants";

export const loadFavorites = createAsyncThunk<
TLoadFavoritesReturnType, { entityType: TFavoritesTypes, limit?: number, offset?: number, isLoadMore?: boolean }
>('favorites/loadFavorites',
  async ({ limit = FAVORITE_LIST_LIMIT, isLoadMore = false, ...params }, { getState }) => {
    const entityType = params.entityType;
    const state = getState()['favorites'];

    const response = await axios.get(api.favorites.get(), {
      params: {
        ...params,
        limit,
        offset: state[entityType].offset,
        entityType: FAVORITES_ENTITY_TYPES[entityType],
      }
    });

    return {
      isLoadMore,
      entityType,
      items: response?.data?.items || [],
      total: response?.data?.total || 0,
    };
  }
);

export const checkIsFavoriteItem = createAsyncThunk<
{ isFavorite: boolean },
TCheckFavoriteRequestParams
>('favorites/checkIsFavoriteItem',
  async ({ entityId, entityType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.favorites.check(), { entityId, entityType });
      return {
        isFavorite: !!response?.data?.result,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const addToFavorite = createAsyncThunk<unknown, TAddToFavoritesRequestParams>('favorites/addToFavorite',
  async(params, { rejectWithValue }) => {
    try {
      await axios.post(api.favorites.post(), params);
    } catch (e) {
      return rejectWithValue(e);
    }
  });

export const removeFromFavorite = createAsyncThunk<
unknown, TDeleteFromFavoritesRequestParams
>('favorites/addToFavorite',
  async({ entityId, entityType }, { rejectWithValue }) => {
    try {
      await axios.put(api.favorites.delete(), { entityId, entityType });
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const favoritesSlice = createSlice<TFavoritesState, any>({
  name: 'favorites',
  initialState,
  reducers: {
    setOffset(state: TFavoritesState, { payload }: PayloadAction<any>) {
      state[payload.entityType].offset = payload.nextOffset;
    },
    clearBlock(state: TFavoritesState, { payload }: PayloadAction<any>) {
      state[payload.entityType].total = 0;
      state[payload.entityType].offset = 0;
      state[payload.entityType].items = [];
    },
    setIsFavoriteFlag(state: TFavoritesState, { payload }: PayloadAction<any>) {
      const { entityId, entityType, isFavorite } = payload;
      state[entityType].items = state[entityType].items.map(item => {
        if (item.entityId === entityId) {
          return { ...item, isFavorite };
        } else {
          return item;
        }
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(loadFavorites.fulfilled, (state, { payload }) => {
      const type = payload.entityType;
      if (!payload.isLoadMore) {
        state[type].items = payload.items.map(item => ({ ...item, isFavorite: true }));
        state[type].total = payload.total;
      }
      if (payload.isLoadMore) {
        state[type].items = [...state[type].items, ...payload.items.map(item => ({ ...item, isFavorite: true }))];
        state[type].total = payload.total;
      }

    });
    builder.addCase(checkIsFavoriteItem.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});


export const setFavoriteOffset = favoritesSlice.actions.setOffset as SliceAction;
export const clearFavoriteBlock = favoritesSlice.actions.clearBlock as SliceAction;
export const setIsFavoriteFlag = favoritesSlice.actions.setIsFavoriteFlag as SliceAction;
