/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getMovieDescription, getStreamPlaceOrEvent } from '../contentUtils';
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${capitalize(tagName)} в Южно-Сахалинске ${year} год. Острова культуры`,
  description: ({ tagName }) => `${capitalize(tagName)} в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};
const tagPageDefaultSeoEvents = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Южно-Сахалинске ${year} год. Острова культуры`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
  titleOg: () => '',
};
const tagPageDefaultSeoPlaces = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} Южно-Сахалинска. Острова Культуры`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
  titleOg: () => '',
};

export const sakhalinContent = {
  timezone: 'Asia/Sakhalin',
  yandexMapState: {
    center: [50.51, 143.08],
    zoom: 9,
  },
  locale: { _id: 233 },
  title: 'Острова Культуры',
  subTitle: 'Культурно-туристический портал Сахалина',
  mainBanner: {
    hide: true,
    title: 'Сахалинская область\nи Курильские острова',
    text: null, // Текст находится в компоненте  самого баннера
    links: []
  },
  useBvi: true,
  phone: '8 800 707-68-65',
  mail: 'support@cultsakhalin.ru',
  agreementLink: '/public_offer',
  agreementFilePath: '/docs/public_offer_09_11_2022.pdf',
  socials: {
    vk: 'https://vk.com/cultsakhalin',
    ok: 'https://ok.ru/group/70000000948832',
    telegram: 'https://t.me/cultsakhalin',
  },
  socialTitles: {
    vk: '«Острова Культуры»\nво «вконтакте»',
    telegram: '«Острова Культуры»\nв Telegram',
  },
  subscribeForm: {
    title: 'Узнавайте новости культуры первыми',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { to: '/places', title: 'Места' },
      { to: '/touristroutes', title: 'Маршруты' },
      { to: '/overviews', title: 'Обзоры' },
      {
        title: 'Live',
        links: [
          { to: '/streams', title: 'Трансляции' },
          { to: '/artefacts', title: 'Дополненная реальность' },
          { to: '/museums', title: 'Виртуальные музеи' },
        ],
      },
      { title: 'Гранты', linksSource: 'sprojects' },
      {
        title: 'Еще',
        links: [
          { title: 'Кафе и рестораны', to: '/restaurants' },
          { title: 'Гостиницы', to: '/hotels' }
        ]
      },
    ],
  },
  footer: {
    title: 'Острова культуры. Сахалинская область',
    systemName: `© ${year}. Острова культуры. Культурно-туристический портал Сахалинской области. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      {
        to: '/events',
        title: 'Афиша'
      },
      {
        to: '/ticketsbundles',
        title: 'Абонементы'
      },
      {
        to: '/places',
        title: 'Места'
      },
      {
        to: '/touristroutes',
        title: 'Маршруты',
      },
      {
        to: '/overviews',
        title: 'Обзоры'
      },
      {
        to: '/hotels',
        title: 'Гостиницы'
      },
      {
        to: '/restaurants',
        title: 'Рестораны'
      },
    ],
    callTime: 'В будние дни с 9:00 до 18:00'
  },
  analytics: {
    yandex: envVal({ production: '91097399' }, null),
    gtm: envVal({ production: 'GTM-P5WD2KP' }, null),
    pixel: envVal({ production: '26845' }, null),
    goals: {
      paidVisit: 'events_visit_sahalin',
      paidBuy: 'buy_ticket_sahalin',
      freeVisit: 'events_visits_sahalin',
      freeBuy: 'freeandzero_sahalin',
      freeNoTicketsVisit: 'noactive_free_sahalin',
      externalLinkVisit: 'events_visit_redirect_sahalin',
      externalLinkBuy: 'buy_redirect_sahalin',
      ticketsAdd: 'entity_ticket_add',
      registration: 'kolvo_registration',
    }
  },
  verificationsMeta: [
    {
      name: 'yandex-verification',
      content: envVal({ production: 'f66facb6e24222c7' }, null),
    },
    {
      name: 'google-site-verification',
      content: envVal({ production: '3KCNnKWU7RVIiEuVf09Bct3-jfmPUwcRoFMPmSXotZA' }, null),
    },
  ],
  unisenderNewsPopup: { // change?
    id: envVal({ staging: '' }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'default',
    socialCardIconTg: 'default',
    pages: {
      index: {
        header: 'transparent',
        heroBlock: 'video',
        videoFormat: 'mov',
        hasMobileVideo: true,
      },
    },
  },
  seo: {
    pages: {
      index: {
        title: () => 'Острова Культуры - Культурно-туристический портал Сахалинской области',
        description: () => 'Острова Культуры - Культурно-туристический портал Сахалинской области. Мероприятия, места, обзоры, маршруты, трансляции, гостиницы, кафе и рестораны',
        titleOg: () => '',
      },
      main: { title: 'Острова Культуры - Культурно-туристический портал Сахалинской области' },
      events: {
        title: () => `Афиша Южно-Сахалинска ${year}. Куда сходить в Южно-Сахалинске развлечься. Купить билеты на мероприятия в Южно-Сахалинске. Острова Культуры`,
        description: () => 'Афиша мероприятий Южно-Сахалинска. Куда можно сходить в Южно-Сахалинске отдохнуть. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры',
        titleOg: () => '',
      },
      'pushkinskaya-karta/events': {
        title: () => `Пушкинская карта в Южно-Сахалинске ${year}. Купить билеты по Пушкинской карте в Южно-Сахалинске. Острова Культуры`,
        description: () => 'Афиша мероприятий по Пушкинской карте. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Острова Культуры',
      },
      'koncerty-i-shou/events': {
        title: () => `Концерты в Южно-Сахалинске ${year}. Афиша концертов в Южно-Сахалинске. Острова Культуры`,
        description: () => 'Расписания концертов в Южно-Сахалинске. Купить билеты на концерты в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры',
      },
      'spektakli/events': {
        title: () => `Спектакли в Южно-Сахалинске ${year}. Купить билеты на спектакль в Южно-Сахалинске. Острова Культуры`,
        description: () => 'Афиша спектаклей в Южно-Сахалинске. Купить билеты на спектакль в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры',
      },
      'detyam/events': {
        title: () => `Детская афиша в Южно-Сахалинске ${year}. Куда пойти с ребенком в Южно-Сахалинске. Острова Культуры`,
        description: () => 'Детские мероприятия в Южно-Сахалинске. Куда пойти с ребенком в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры',
      },
      'tagPageDefaultSeoevents': tagPageDefaultSeoEvents,
      places: {
        title: () => `Все интересные места и достопримечательности Южно-Сахалинска ${year}`,
        description: () => `Интересные и необычные достопримечательности в Южно-Сахалинске ${year} — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте. Сахалин`,
        titleOg: () => '',
      },
      'obuchenie/places': {
        title: () => `Образовательные учреждения и места Южно-Сахалинска. Острова Культуры`,
        description: () => `Образовательные учреждения и места в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
        titleOg: () => '',
      },
      'detyam/places': {
        title: () => `Детские места Южно-Сахалинска. Острова Культуры`,
        description: () => `Места для детей в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
        titleOg: () => '',
      },
      'prochie/places': {
        title: () => `Красивые места Южно-Сахалинска. Острова Культуры`,
        description: () => `Красивые места в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
        titleOg: () => '',
      },
      'na-vozdukhe/places': {
        title: () => `Необычные места Южно-Сахалинска. Острова Культуры`,
        description: () => `Необычные в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
        titleOg: () => '',
      },
      'sport/places': {
        title: () => `Спортивные объекты и места Южно-Сахалинска. Острова Культуры`,
        description: () => `Спортивные объекты и места в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
        titleOg: () => '',
      },
      'muzyka/places': {
        title: () => `Музыкальные места Южно-Сахалинска. Острова Культуры`,
        description: () => `Музыкальные места в Южно-Сахалинске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Острова Культуры`,
        titleOg: () => '',
      },
      'tagPageDefaultSeoplaces': tagPageDefaultSeoPlaces,
      'tagPageDefaultSeo': tagPageDefaultSeo,
      routes: {
        title: () => `Туристические маршруты в Южно-Сахалинске ${year}. Сахалин`,
        description: () => 'Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле. Карты туристических маршрутов, описание, необычные идеи для путешествия в Южно-Сахалинске',
        titleOg: () => '',
      },
      overviews: {
        title: () => `Обзоры ${year} — Сахалин`,
        description: () => `Обзоры лучших событий, мест ${year} — Сахалин`,
        titleOg: () => '',
      },
      hotels: {
        title: () => `Гостиницы в Южно-Сахалинске ${year}`,
        description: () => 'Гостиницы в Южно-Сахалинске. Подробная информация: описания, адрес, режимы работы, фото, адреса на карте, рейтинги. Сахалин',
        titleOg: () => '',
      },
      restaurants: {
        title: () => `Рестораны в Южно-Сахалинске ${year}`,
        description: () => 'Рестораны в Южно-Сахалинске. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Сахалин',
        titleOg: () => '',
      },
      streams: {
        title: () => `Трансляции - Сахалинская область ${year}. Острова Культуры`,
        description: () => `Трансляции - Сахалинская область ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Острова Культуры`,
        titleOg: () => '',
        h1: () => `Трансляции - Сахалинская область ${year}`,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Сахалин',
        description: () => 'Абонементы в Сахалине',
        titleOg: () => '',
      },
      movies: {
        title: () => `Киноафиша в Южно-Сахалинске | Афиша мероприятий с точными датами на ${year} год - Острова культуры`,
        description: () => `Киноафиша в Южно-Сахалинске на ${year} год ✅ Афиша интересных мероприятий в Сахалинской области. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Острова культуры`,
        titleOg: () => '',
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время. Адрес места проведения — ${entity.address}. Афиша`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName}, ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}. Подробная информация: описание, фото. Официальный сайт — Сахалина`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}. Фото, режим работы, афиша и события. Сахалин`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все туристические места и достопримечательности на сайте — Сахалина`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание, адреса мест на карте, фото. Сахалин`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле. Сахалин`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Сахалин`,
        description: ({ entity }) => `Обзор - ${entity.name}. Официальный сайт Сахалина`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleHotel: {
        title: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address}. Фото, режим работы. Сахалин`,
        description: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте — Сахалина`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRestaurant: {
        title: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address}. Фото, режим работы. Сахалин`,
        description: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте — Сахалина`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на сайте Острова Культуры`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''}  на сайте Острова Культуры`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Пакет билетов ${entity?.name}. Сахалин`,
        description: ({ entity }) => `Пакет билетов ${entity?.name}. Купить билеты в Сахалине`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleMovie: {
        title: ({ entity }) => `${entity?.name} - купить билеты в кино. Расписания киносеансов в Сахалинской области`,
        description: ({ entity }) => `${entity?.name} - афиша сеансов в Сахалинской области, продажа билетов по Пушкинской карте! Описание фильма:` +
          `${getMovieDescription(entity)}` +
          '. Официальный сайт Острова Культуры',
        titleOg: ({ entity }) => `${entity.name}`
      },
      pushkincard: {
        title: () => ``,
        description: () => '',
        titleOg: () => ``,
      },
      faq: {
        title: () => `Часто задаваемые вопросы. Острова Культуры - Культурно-туристический портал Сахалинской области`,
        description: () => 'Часто задаваемые вопросы. Острова Культуры - Культурно-туристический портал Сахалинской области. Мероприятия, места, обзоры, маршруты, трансляции, гостиницы, кафе и рестораны',
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} - афиша ${year}. Интересные развлекательные события. Острова Культуры`,
            description: ({ localeName }) => `${localeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места - ${localeName} ${year}. Острова Культуры`,
            description: ({ localeName }) => `Все интересные места и достопримечательности - ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте. Официальный сайт Острова Культуры`,
            h1: ({ localeName }) => `Места - ${localeName}`
          },
          restaurantsPage: {
            title: ({ localeName }) => `Кафе и рестораны - ${localeName} ${year}. Острова Культуры`,
            description: ({ localeName }) => `Кафе и рестораны - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Острова Культуры`,
            h1: ({ localeName }) => `Кафе и рестораны - ${localeName}`
          },
          hotelsPage: {
            title: ({ localeName }) => `Гостиницы и отели - ${localeName} ${year}. Острова Культуры`,
            description: ({ localeName }) => `Гостиницы и отели - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Острова Культуры`,
            h1: ({ localeName }) => `Гостиницы и отели - ${localeName}`
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}. Интересные развлекательные события. Острова Культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Острова Культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Острова Культуры`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            hotelsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Острова Культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Острова Культуры`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            restaurantsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Острова Культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Острова Культуры`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            }
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Острова Культуры`,
              description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Острова Культуры`,
              description: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Сахалинской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Острова Культуры`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Острова Культуры`,
            description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции - ${placeName} ${year}. Острова Культуры`,
            description: ({ placeName }) => `Трансляции - ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Острова Культуры`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Острова Культуры`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Сахалинской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Острова Культуры`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные трансляции - Сахалинская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Острова Культуры`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Острова Культуры`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции - Сахалинская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Интересные развлекательные события. Острова Культуры`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Южно-Сахалинска на ${formattedDate} года. Интересные развлекательные события в Южно-Сахалинске. Острова Культуры`,
            description: ({ formattedDate }) => `Афиша в Южно-Сахалинске на ${formattedDate} года. Интересные мероприятия Южно-Сахалинска. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша в Южно-Сахалинске на ${formattedDate}. Кино в Южно-Сахалинске - ${formattedDate}. Острова Культуры`,
            description: ({ formattedDate }) => `Кино в Южно-Сахалинске - ${formattedDate} ✅ Киноафиша в Южно-Сахалинске на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Острова Культуры`,
            description: ({ formattedDate }) => `Трансляции - ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Острова Культуры`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные развлекательные события. Острова Культуры`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные мероприятия - Сахалинская область. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года - Сахалинская область. Острова Культуры`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года - Сахалинская область. Киноафиша на ${formattedDate} года - Сахалинская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} в Южно-Сахалинске. Интересные развлекательные события в Южно-Сахалинске. Острова Культуры`,
            description: ({ interim, formattedDate }) => `Афиша Южно-Сахалинска на ${interim}, ${formattedDate}. Интересные мероприятия Южно-Сахалинска. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года в Южно-Сахалинске. Кино в Южно-Сахалинске ${interim}, на ${formattedDate} ${formattedYear} года. Острова Культуры`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино в Южно-Сахалинске на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша в Южно-Сахалинске ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Острова Культуры`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Острова Культуры`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Острова Культуры`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Сахалинская область. Острова Культуры`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Сахалинская область. Киноафиша на ${interim} ${formattedDate} ${year} года - Сахалинская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} в Южно-Сахалинске. Купить билеты по Пушкинской карте в Южно-Сахалинске. Острова Культуры`,
              description: () => `Афиша мероприятий по Пушкинской карте Южно-Сахалинска. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Острова Культуры`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты в Южно-Сахалинске ${year}. Афиша концертов Южно-Сахалинска. Острова Культуры`,
              description: () => `Расписания концертов в Южно-Сахалинске. Купить билеты на концерты в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли в Южно-Сахалинске ${year}. Купить билеты на спектакль в Южно-Сахалинске. Острова Культуры`,
              description: () => `Афиша спектаклей в Южно-Сахалинске. Купить билеты на спектакль в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша в Южно-Сахалинске ${year}. Куда пойти с ребенком в Южно-Сахалинске. Острова Культуры`,
              description: () => `Детские мероприятия в Южно-Сахалинске. Куда пойти с ребенком в Южно-Сахалинске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Острова Культуры`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции - ${tagName}. Острова Культуры`,
              description: ({ tagName }) => `Трансляции - ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт Острова Культуры`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
          restaurantsPage: {
            default: {
              title: ({ tagName }) => `${tagName} Южно-Сахалинска ${year}. Острова Культуры`,
              description: ({ tagName }) => `${tagName} в Южно-Сахалинске. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Острова Культуры`,
              h1: ({ tagName }) => tagName,
            }
          },
          hotelsPage: {
            default: {
              title: ({ tagName }) => `Гостиницы и отели - ${tagName} ${year}. Острова Культуры`,
              description: ({ tagName }) => `Гостиницы и отели - ${tagName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Острова Культуры`,
              h1: ({ tagName }) => `Гостиницы и отели - ${tagName}`,
            }
          }
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша в Южно-Сахалинске, жанр - ${genreName}. Кино в Южно-Сахалинске, жанр - ${genreName}. Острова Культуры`,
            description: ({ genreName }) => `Кино в Южно-Сахалинске, жанр - ${genreName} ✅ Киноафиша в Южно-Сахалинске, жанр - ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Острова Культуры`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
  homePageBlocks: [
    'best',
    'events',
    'subscribe',
    'places',
    'overviews',
    'routes',
    'socials',
    'restaurants',
    'hotels',
    'ticketsBundles'
  ]
};
