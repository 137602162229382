import React, { ReactElement, useState } from "react";
import style from "./Footer.v2.module.scss";
import Link from "next/link";
import { regionContent } from "@kursk/content/regionContent";
import { WriteToUsModal } from "@kursk/components/Layout/WriteToUsModal/WriteToUsModal";
import { 
  DzenIcon,
  OkIcon,
  CultureFooterLogo,
  RutubeIcon,
  TelegramIcon,
  VkIcon,
  YoutubeIcon
} from "@kursk/components/ui/icons";
import { IMenuItem } from "@kursk/components/Layout/Layout";

export interface IProps {
  links: IMenuItem[];
  useLogo?: string;
}

export function FooterV2({ links, useLogo }: IProps): ReactElement {
  const [isOpenWriteToUsModal, setIsOpenWriteToUsModal] = useState(false);

  const renderTitleOrLogo = () => useLogo ? (
    <Link href={'/'}>
      <a className={style.logo_wrap}>
        <img className={style.logo} src={'/images/logo_footer.svg'} alt="footer logo" />
      </a>
    </Link>
  ) : (
    <div className={style.titleText}>{regionContent.footer.title}</div>
  );

  const socialLinks = [
    regionContent.socials.ok && { link: regionContent.socials.ok, icon: <OkIcon/> },
    regionContent.socials.vk && { link: regionContent.socials.vk, icon: <VkIcon/> },
    regionContent.socials.yaDzen && { link: regionContent.socials.yaDzen, icon: <DzenIcon/> },
    regionContent.socials.telegram && { link: regionContent.socials.telegram, icon: <TelegramIcon/> },
    regionContent.socials.youtube && { link: regionContent.socials.youtube, icon: <YoutubeIcon/> },
    regionContent.socials.rutube && { link: regionContent.socials.rutube, icon: <RutubeIcon /> },
  ].filter(item => Boolean(item));

  return (
    <>
      <WriteToUsModal
        isOpen={isOpenWriteToUsModal}
        setIsOpen={setIsOpenWriteToUsModal}
      />
      <div className={style.wrapper}>
        <div className={style.row}>
          <div className={style.logoAndInfo}>
            {renderTitleOrLogo()}
            <ul className={style.helpers}>
              <li className={style.helpers__item}>
                <Link href='/contacts'>Контакты</Link>
              </li>
              <li className={style.helpers__item}>
                <Link href='/faq'>Часто задаваемые вопросы</Link>
              </li>
              <li className={style.helpers__item}>
                <a target='_blank' href='/api/admin-redirect' rel="noreferrer">Вход для партнеров</a>
              </li>
            </ul>
          </div>
          <ul className={style.menu}>
            {links.map((item) =>
              <li key={item.to} className={style.menuLink}>
                {item.target ?
                  (<a href={item.to} target={item.target}>{item.title}</a>) :
                  (<Link href={item.to}>{item.title}</Link>)
                }
              </li>
            )}
          </ul>
          <div className={style.support}>
            <div className={style.info}>
              {regionContent.phone &&
                <>
                  <div className={style.info__title}>Техническая поддержка</div>
                  <div className={style.info__phone}>
                    <a href={`tel:${regionContent.phone}`}>{regionContent.phone}</a>
                  </div>
                  <div className={style.info__callTime}>{regionContent.footer.callTime}</div>
                </>
              }
              <div className={style.info__mail}>
                <a href={`mailto:${regionContent.mail}`}>{regionContent.mail}</a>
              </div>
            </div>
            <div className={style.socials}>
              {socialLinks.map(({ link, icon }) => (
                <a key={link} href={link} rel="noreferrer" target="_blank">
                  <div className={style.socialIcon}>
                    {icon}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      {regionContent.footer.showProCultureLogo ? (
        <div className={style.copyright}>
          <div className={style.copyright__in}>
            <div className={style.copyright__text}>
              {regionContent.footer.systemName}
            </div>
            <CultureFooterLogo className={style.copyright__svg}/>
          </div>
        </div>
      ) : (
        <div className={style.copyright}>{regionContent.footer.systemName}</div>
      )}
    </>
  )
}
