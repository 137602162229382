import React, { ReactElement, useState } from "react";
import style from "./Footer.bel.module.scss";
import Link from "next/link";
import { regionContent } from "@kursk/content/regionContent";
import { WriteToUsModal } from "@kursk/components/Layout/WriteToUsModal/WriteToUsModal";
import { DzenIcon, OkIcon, CultureFooterLogo, TelegramIcon, Tiktok, VkIcon } from "@kursk/components/ui/icons";
import { useOrientations } from "@common/hooks/useOrientation";
import { IMenuItem } from "@kursk/components/Layout/Layout";

interface IProps {
  links: IMenuItem[];
}

export function FooterBel({ links }: IProps): ReactElement {
  const [isOpenWriteToUsModal, setIsOpenWriteToUsModal] = useState(false);
  const orientation = useOrientations();

  const showWriteToUsModal = () => {
    setIsOpenWriteToUsModal(true);
  }

  const socialLinks = [
    regionContent.socials.tiktok && { link: regionContent.socials.tiktok, icon: <Tiktok/> },
    regionContent.socials.yaDzen && { link: regionContent.socials.yaDzen, icon: <DzenIcon/> },
    regionContent.socials.telegram && { link: regionContent.socials.telegram, icon: <TelegramIcon/> },
    regionContent.socials.ok && { link: regionContent.socials.ok, icon: <OkIcon/> },
    regionContent.socials.vk && { link: regionContent.socials.vk, icon: <VkIcon/> },
  ].filter(item => Boolean(item));

  const CentralSection = ({ className }) => (
    <div className={className}>
      <div className={style.footer__logo}/>
      <div className={style.footer__socials}>
        {socialLinks.map(({ link, icon }) => (
          <a key={link} href={link} rel="noreferrer" target="_blank">
            <div className={style.footer__socialIcon}>
              {icon}
            </div>
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <WriteToUsModal
        isOpen={isOpenWriteToUsModal}
        setIsOpen={setIsOpenWriteToUsModal}
      />
      <div className={style.footer__wrapper}>
        <div className={style.footer__row}>
          <div className={style.footer__section}>
            <div className={style.footer__info}>
              {regionContent.phone &&
              <>
                <div className={style.footer__title}>Техническая поддержка</div>
                <div className={style.footer__phone}>
                  <a href={`tel:${regionContent.phone}`}>{regionContent.phone}</a>
                </div>
                <div className={style.footer__callTime}>{regionContent.footer.callTime}</div>
              </>
              }
              <div className={style.footer__mail}>
                <a href={`mailto:${regionContent.mail}`}>{regionContent.mail}</a>
              </div>
              <div className={style.footer__writeUs}>
                <a onClick={showWriteToUsModal}>Напишите нам</a>
              </div>
              <div className={style.footer__faq}>
                <Link href='/faq'>Часто задаваемые вопросы</Link>
              </div>
              <div className={style.footer__pushkincard}>
                <a target='_blank' href='/docs/cultreg_bel_presentation.pdf' rel="noreferrer">
                  Презентация
                </a>
              </div>
              <div className={style.footer__login}>
                <a target='_blank' href='/api/admin-redirect' rel="noreferrer">Вход для организаторов</a>
              </div>
            </div>
          </div>
          {orientation === 'mobile' && <CentralSection className={style.footer__section}/>}
          <div className={style.footer__section}>
            {links.map((item) =>
              <div key={item.to} className={style.footer__menuLink}>
                {item.target ?
                  (<a href={item.to} target={item.target}>{item.title}</a>) :
                  (<Link href={item.to}>{item.title}</Link>)
                }
              </div>
            )}
          </div>
        </div>
        {/**
         * HACK Чтобы центральный блок находился строго по центру,
         * а текст в блоках слева и справа прилегал с границам контейнера: http://joxi.ru/GrqzOyaS4eK41m
         */}
        {
          (orientation === 'desktop' || orientation === 'tablet') && (
            <CentralSection className={style.footer__section_center}/>
          )
        }
      </div>
      <div className={style.copyright}>
        {regionContent.footer.showProCultureLogo && (
          <CultureFooterLogo className={style.copyright__svg} />
        )}
        <div className={style.copyright__text}>
          {regionContent.footer.systemName}
        </div>
      </div>
    </>
  )
}
