import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../urls";

interface IState {
  items: string[];
}

export const loadSubjectNames = createAsyncThunk<string[], any>('subjectNames/load', async () => {
  const response = await axios.put(api.goods.getSubjectNames.put());

  if (!response?.data?.subjectNames?.length) {
    return null;
  }

  return response?.data?.subjectNames;
});

export const subjectNamesSlice = createSlice<IState, any>({
  name: 'subjectNames',
  initialState: {
    items: [],
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadSubjectNames.fulfilled, (state, { payload }) => {
      if (payload) {
        state.items = payload;
      }
    });
  }
});
