import React, { ReactElement } from 'react';
import { VideoContent } from "./VideoContent/VideoContent";
import { useOrientations } from "@common/hooks/useOrientation";
import styles from './Video.module.scss';
import { regionContent } from "@kursk/content/regionContent";

export function Video(props): ReactElement {
  const orientation = useOrientations();

  const componentsConfigByPage = regionContent.componentsConfig.pages[props.page];

  let posterUrl = '/video_page_index_poster.png';
  let videoMovUrl = '/video-page-index.mov';

  switch (orientation) {
    case "mobile":
      posterUrl = '/video_page_index_poster_mobile.png';

      if (componentsConfigByPage?.hasMobileVideo) {
        videoMovUrl = '/video-page-index-mobile.mov'
      }
      break;
    case "tablet":
      posterUrl = '/video_page_index_poster_tablet.png';
      break;
  }
  return (
    <div className={styles.videoWrapper}>
      <div className={styles.videoContentWrap}>
        <video key={videoMovUrl} poster={posterUrl} autoPlay playsInline muted loop disablePictureInPicture >
          {componentsConfigByPage?.videoFormat === 'mov' ? (
            <>
              <source src={videoMovUrl} />
            </>
          ) : (
            <>
              <source src="/video_page_index.webm" type="video/webm"/>
              <source src="/video_page_index.mp4" type="video/mp4"/>
            </>
          )}
        </video>
        <VideoContent/>
      </div>
    </div>
  );
}
