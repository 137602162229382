import { IPlace, IRestaurant } from "./places";
import { IEvent } from "./event";
import { IArticle, IRoute } from "./articles";
import { ITour } from "./tour";
import { IExcursion } from "./excursion";
import { TGuideGoExcursion } from "./excursion-guidego";

export enum FavoritesTypesEnum {
  Places = 'places',
  Events = 'events',
  Tours = 'tours',
  Excursions = 'excursions',
  TouristRoutes = 'touristroutes',
  Restaurants = 'restaurants',
  Overviews = 'overviews'
}

export type TFavoritesTypes = (typeof FavoritesTypesEnum)[keyof typeof FavoritesTypesEnum];

export const FAVORITES_ENTITY_TYPES = {
  [FavoritesTypesEnum.Places]: 'place',
  [FavoritesTypesEnum.Events]: 'event',
  [FavoritesTypesEnum.Tours]: 'tour',
  [FavoritesTypesEnum.Excursions]: 'excursion',
  [FavoritesTypesEnum.TouristRoutes]: 'touristRoute',
  [FavoritesTypesEnum.Restaurants]: 'restaurant',
  [FavoritesTypesEnum.Overviews]: 'article',
};

export const ENABLED_FAVORITES_ENTITY_TYPES = [...Object.keys(FAVORITES_ENTITY_TYPES)];

export interface IFavoriteEntity<T> {
  items: {
    entity: T;
    entityId: number;
    entityType: string;
    id: number;
    source: 'guideGo' | null;
  }[];
  total: number;
  offset?: number;
}

export type TFavoritesStateByType = {
  [FavoritesTypesEnum.Places]: IFavoriteEntity<IPlace>;
  [FavoritesTypesEnum.Events]: IFavoriteEntity<IEvent>;
  [FavoritesTypesEnum.Tours]: IFavoriteEntity<ITour>;
  [FavoritesTypesEnum.Excursions]: IFavoriteEntity<IExcursion | TGuideGoExcursion>;
  [FavoritesTypesEnum.TouristRoutes]: IFavoriteEntity<IRoute>;
  [FavoritesTypesEnum.Restaurants]: IFavoriteEntity<IRestaurant>;
  [FavoritesTypesEnum.Overviews]: IFavoriteEntity<IArticle>;
}

export type TFavoritesState = TFavoritesStateByType & {
  isFavorite: boolean;
}

export type TLoadFavoritesReturnType = {
  entityType: TFavoritesTypes,
  items: TFavoritesStateByType[TFavoritesTypes]["items"],
  total: TFavoritesStateByType[TFavoritesTypes]["total"],
  isLoadMore: boolean;
}

type TFavoritesRequestParams = {
  entityId: string;
  entityType: string;
}

export type TCheckFavoriteRequestParams = TFavoritesRequestParams;
export type TAddToFavoritesRequestParams = TFavoritesRequestParams;
export type TDeleteFromFavoritesRequestParams = TFavoritesRequestParams;
