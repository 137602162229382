/* eslint-disable max-len */

export const SearchIcon = ({ width = 23, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.78436 17.1226C10.748 17.1226 11.7023 16.9328 12.5926 16.564C13.4829 16.1953 14.2919 15.6547 14.9733 14.9733C15.6547 14.2919 16.1953 13.4829 16.564 12.5926C16.9328 11.7023 17.1226 10.748 17.1226 9.78436C17.1226 8.82068 16.9328 7.86644 16.564 6.97612C16.1953 6.0858 15.6547 5.27684 14.9733 4.59542C14.2919 3.914 13.4829 3.37346 12.5926 3.00468C11.7023 2.6359 10.748 2.44609 9.78436 2.44609C7.83813 2.44609 5.97161 3.21923 4.59542 4.59542C3.21923 5.97161 2.44609 7.83813 2.44609 9.78436C2.44609 11.7306 3.21923 13.5971 4.59542 14.9733C5.97161 16.3495 7.83813 17.1226 9.78436 17.1226ZM9.78436 19.5687C12.3793 19.5687 14.868 18.5379 16.7029 16.7029C18.5379 14.868 19.5687 12.3793 19.5687 9.78436C19.5687 7.18939 18.5379 4.7007 16.7029 2.86577C14.868 1.03085 12.3793 0 9.78436 0C7.18939 0 4.7007 1.03085 2.86577 2.86577C1.03085 4.7007 0 7.18939 0 9.78436C0 12.3793 1.03085 14.868 2.86577 16.7029C4.7007 18.5379 7.18939 19.5687 9.78436 19.5687Z" fill="#151516"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0715 15.0308C15.3016 14.8023 15.6131 14.6746 15.9374 14.6758C16.2617 14.6769 16.5723 14.8068 16.8009 15.0369L21.6625 19.9291C21.8847 20.1604 22.0072 20.4697 22.0035 20.7904C21.9999 21.1112 21.8704 21.4176 21.6429 21.6438C21.4155 21.8699 21.1083 21.9977 20.7876 21.9995C20.4668 22.0014 20.1582 21.8771 19.9282 21.6536L15.0666 16.7614C14.838 16.5314 14.71 16.22 14.7109 15.8957C14.7119 15.5714 14.8415 15.2608 15.0715 15.032V15.0308Z" fill="#151516"/>
  </svg>
);

export const ProfileIcon = ({ width = 21, height = 25 }) => (
  <svg width={width} height={height} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 13.8C13.151 13.8 15.3 11.651 15.3 9C15.3 6.34903 13.151 4.2 10.5 4.2C7.84903 4.2 5.7 6.34903 5.7 9C5.7 11.651 7.84903 13.8 10.5 13.8ZM10.5 16C14.366 16 17.5 12.866 17.5 9C17.5 5.13401 14.366 2 10.5 2C6.63401 2 3.5 5.13401 3.5 9C3.5 12.866 6.63401 16 10.5 16Z" fill="#151516"/>
    <path d="M17.4909 24C17.497 23.881 17.5 23.7612 17.5 23.6406C17.5 19.7746 14.366 16.6406 10.5 16.6406C6.63401 16.6406 3.5 19.7746 3.5 23.6406C3.5 23.7612 3.50305 23.881 3.50907 24H1.50704C1.50236 23.8808 1.5 23.761 1.5 23.6406C1.5 18.6701 5.52944 14.6406 10.5 14.6406C15.4706 14.6406 19.5 18.6701 19.5 23.6406C19.5 23.761 19.4976 23.8808 19.493 24H17.4909Z" fill="#151516"/>
  </svg>
);
