import React, { ReactElement } from 'react';
import { BannerWidgetEvents } from 'components/ui/BannerWidgetEvents/BannerWidgetEvents';
import styles from './VideoContent.sakhalin.module.scss';

export function VideoContentSakhalin(): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{`Сахалин\nи Курилы`}</div>
      <div className={styles.bottomBlock}>
        <BannerWidgetEvents />
      </div>
    </div>
  );
}
